export const normalizeRows = (headers: string[], rows: [][]) => {
    return rows.map((row: any[]) => {
        let obj: {[key: string]: any} = {};

        row.forEach((value, index) => {
            obj[headers[index]] = value;
        });

        return obj;
    })
};