import { FC, useEffect } from 'react';
import { RoutesProvider } from './Components/Routes/RoutesProvider.com';

import { Text } from './Components/Simple/Text/Text.com';
import { Element } from './Components/Simple/Layout/Element/Element.com';
import { FlexElement } from './Components/Simple/Layout/Flex/FlexElement/FlexElement.com';
import { Row } from './Components/Simple/Layout/Flex/Row/Row.com';
import { Col } from './Components/Simple/Layout/Flex/Col/Col.com';
import { Grid } from './Components/Simple/Layout/Grid/Grid.com';
import './Theme.css';
import './App.css';
import { Header } from './Components/Complex/Header/Header.com';
import { useAuth } from './Hooks/UseAuth/UseAuth.hook';

const App: FC = () => {
  const { isAuth, check } = useAuth();

  // Check Auth State
  useEffect(() => {
    if (!isAuth) {
      check();
    }
  }, []);

  // Loader
  if (!isAuth) {
    // return null;
  }
  
  // App
  return <RoutesProvider />
  // return (
  //   <>
  //     {/* <Header /> */}
  //     {/* <Grid type="row" template="80px 1fr 80px"> */}

  //       {/* <Col>
  //         <Row>111</Row>
  //         <Col scroll={true}>
  //           <Row style={{height: '1000px'}}>111</Row>
  //         </Col>
  //         <Row>333</Row>
  //       </Col> */}
  //       {/* <Row>
  //         <Col>111</Col>
  //         <Col>222</Col>
  //       </Row>
  //       <Row>
  //         <Col>111</Col>
  //         <Col>222</Col>
  //       </Row> */}
  //     {/* </Grid> */}
  //     {/* <Element paddingTop="1" marginBottom="1.5">element</Element>
  //     <Row>
  //       <Row paddingTop="1" marginBottom="1.5" grow="1">111</Row>
  //       <Row paddingTop="1" marginBottom="1.5">222</Row>
  //     </Row> */}
  //     {/* <Text size={0}>Lorem ipsum size 0</Text><br />
  //     <Text size={1}>Lorem ipsum size 1</Text><br />
  //     <Text size={2}>Lorem ipsum size 2</Text><br />
  //     <Text size={3}>Lorem ipsum size 3</Text><br />
  //     <Text size={4}>Lorem ipsum size 4</Text><br />
  //     <Text size={5}>Lorem ipsum size 5</Text><br />
  //     <Text size={6}>Lorem ipsum size 6</Text><br />
  //     <Text size={7}>Lorem ipsum size 7</Text><br />
  //     <Text size={8}>Lorem ipsum size 8</Text><br />
  //     <Text size={9}>Lorem ipsum size 9</Text><br />
  //     <Text size={10}>Lorem ipsum size 10</Text><br />
  //     <Text size={11}>Lorem ipsum size 11</Text><br />
  //     <Text size={12}>Lorem ipsum size 12</Text> */}
  //   </>
  // );
}

export default App;
